import React, { useState, useEffect, useRef } from "react";
import Accordion from "@mui/material/Accordion";
import AccordionSummary from "@mui/material/AccordionSummary";
import AccordionDetails from "@mui/material/AccordionDetails";
import Typography from "@mui/material/Typography";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import Gallery from "../Gallery";
import { FiUpload } from "react-icons/fi";
import Swal from "sweetalert2";
import { AiFillDelete } from "react-icons/ai";
import ReactSwitch from "react-switch";
import { FaRegHeart, FaShoppingCart, FaUser } from "react-icons/fa";
import { BiSearch } from "react-icons/bi";
import axios from "axios";
import Modal from "react-modal";
import { BASE_URL } from "../../config";
import useAxios from "../../Axios/useAxios";
import useTokenFromURL from "../../Context/useTokenFromURL";
// Base URL from environment variables. It's used for API calls.
const REACT_APP_BASE_URL = BASE_URL;

/**
 * HeaderCustomization component provides UI & functionality for customizing the header.
 * @param {object} props - Component props
 * @param {string} props.activeAccordion - The currently active accordion name.
 * @param {function} props.onToggle - Callback to toggle the accordion state.
 * @param {boolean} props.IsSavedInputs - Indicates if the inputs were saved.
 */
const HeaderCustomization = ({ activeAccordion, onToggle, IsSavedInputs }) => {
  // Custom hook to get token from URL (not shown in the provided code).
  useTokenFromURL();
  // Custom hook to set up Axios (not shown in the provided code).
  useAxios();

  // State variables declaration
  const [isImageModalOpen, setIsImageModalOpen] = useState(false); // Controls the visibility of the image modal
  const [isSplashImageModalOpen, setIsSplashImageModalOpen] = useState(false); // Controls the visibility of the image modal
  const [selectedHeader, setSelectedHeader] = useState("header1"); // Stores the type of header selected by user
  const [dataSubmitted, setDataSubmitted] = useState(false); // Indicates if data has been submitted already
  const [image, setImage] = useState(null); // Holds the URL or data of the selected image
  const [splashImage, setSplashImage] = useState(null); // Holds the URL or data of the selected image
  const [errors, setErrors] = useState({}); // Holds any validation errors
  const [selectedDimension, setSelectedDimension] = useState("");
  const [isSelectBoxOpen, setIsSelectBoxOpen] = useState(false);
  const [supportPhoneNumber, setSupportPhoneNumber] = useState("");
  const [supportChatLink, setSupportChatLink] = useState("");
  const [isMultiKeywordEnabled, setIsMultiKeywordEnabled] = useState(false);
  const [multiKeywords, setMultiKeywords] = useState([
    { id: Date.now(), value: "" },
  ]);
  const dropdownRef = useRef(null);
  const [errorImagesUpload, setErrorImagesUpload] = useState({
    brand_logo: "",
    page_not_found_img: "",
    some_went_wrong_img: "",
    no_data_found_img: "",
    product_not_found_img: "",
  });
  const [activeInputField, setActiveInputField] = useState(null);
  // Common CSS classes for icons and input elements
  const commonIconClasses = "text-2xl";
  const commonInputClasses = "bg-transparent ml-2 focus:outline-none";

  /**
   * Fetches the title bar data from API and updates local state accordingly.
   */
  const fetchTitleBarData = async () => {
    try {
      const response = await axios.get(`${REACT_APP_BASE_URL}/title-bar`);
      if (response.data && response.data.length > 0) {
        const fetchedData = response.data[0];
        const submitValidate = fetchedData.logo_url !== "" ? true : false;

        setDataSubmitted(submitValidate);

        // Updates the state with the fetched data
        // setErrorImagesUpload.brand_logo(fetchedData.logo_url);
        setErrorImagesUpload((prevState) => ({
          ...prevState,
          brand_logo: fetchedData.logo_url,
          page_not_found_img: fetchedData.page_not_found_screen_url,
          some_went_wrong_img: fetchedData.somethin_went_wrong_screen_url,
          no_data_found_img: fetchedData.no_data_found_screen_url,
          product_not_found_img: fetchedData.product_not_found_screen_url,
        }));
        setSplashImage(fetchedData.image_url);
        setSupportPhoneNumber(fetchedData.mobile_number);
        setSupportChatLink(fetchedData.chat_link);

        // Transform the search_key array to match the expected structure for multiKeywords
        const transformedSearchKeys = fetchedData.search_key.map(
          (key, index) => ({
            id: index, // You might want to use a more unique identifier here
            value: key,
          })
        );

        setMultiKeywords(transformedSearchKeys); // Set the transformed search keys
        setIsMultiKeywordEnabled(fetchedData.is_search_key_enabled === 1); // Convert to boolean
      }
    } catch (error) {
      console.error("Error fetching title bar data:", error);
    }
  };

  // React effect to fetch title bar data on component mount
  useEffect(() => {
    fetchTitleBarData();
  }, []);
  const handleAddKeywordInput = () => {
    setMultiKeywords([...multiKeywords, { id: Date.now(), value: "" }]);
  };

  const handleKeywordChange = (id, newValue) => {
    const updatedKeywords = multiKeywords.map((keyword) =>
      keyword.id === id ? { ...keyword, value: newValue } : keyword
    );
    setMultiKeywords(updatedKeywords);
  };

  const handleDeleteKeyword = (id) => {
    const filteredKeywords = multiKeywords.filter(
      (keyword) => keyword.id !== id
    );
    setMultiKeywords(filteredKeywords);
  };
  /**
   * Handles the form submission to update or save the title bar data.
   */
  const handleSubmit = async () => {
    // Create an array of search keywords from multiKeywords state
    // Create an array of search keywords from multiKeywords state, only if the toggle is enabled
    const searchKeywords = isMultiKeywordEnabled
      ? multiKeywords.map((keyword) => keyword.value)
      : [];

    // Dynamic payload based on the component's states
    const payload = {
      logo_url: errorImagesUpload.brand_logo, // Use the selected image or a default one
      image_url: splashImage,
      mobile_number: supportPhoneNumber,
      chat_link: supportChatLink,
      search_key: searchKeywords, // Set based on toggle state
      is_search_key_enabled: isMultiKeywordEnabled ? 1 : 0,
      page_not_found_screen_url: errorImagesUpload.page_not_found_img,
      somethin_went_wrong_screen_url: errorImagesUpload.some_went_wrong_img,
      no_data_found_screen_url: errorImagesUpload.no_data_found_img,
      product_not_found_screen_url: errorImagesUpload.product_not_found_img,
    };

    try {
      const response = await axios.patch(
        `${REACT_APP_BASE_URL}/title-bar`,
        payload
      );
      if (response.data && response.data.status === "success") {
        fetchTitleBarData();
        Swal.fire({
          title: "Success!",
          text: "Datas Updated successfully.",
          icon: "success",
          timer: 3000,
          showConfirmButton: false,
        });
      }
    } catch (error) {
      Swal.fire(
        "Error!",
        "An error occurred while updating the data.",
        "error"
      );
    }
  };
  /**
   * Validates the fields and updates the errors state.
   * @returns {boolean} - Returns true if no validation errors, false otherwise.
   */
  const validateFields = () => {
    let validationErrors = {};

    // Check if image is provided
    if (!errorImagesUpload.brand_logo) {
      validationErrors.image = "Image is required.";
    }

    setErrors(validationErrors);

    return Object.keys(validationErrors).length === 0; // returns true if no errors, false otherwise
  };

  // Remember to call this validation function at appropriate places, like when the form is submitted or the phone number field is updated.

  // React effect to validate fields whenever the image state changes
  useEffect(() => {
    validateFields();
  }, [errorImagesUpload]);

  /**
   * Handles the accordion change to toggle between open and close state.
   */
  const handleAccordionChange = () => {
    if (activeAccordion === "HeaderAccordian") {
      onToggle && onToggle(null); // close the current accordion if it's 'AuthAccordian'.
    } else {
      onToggle && onToggle("HeaderAccordian"); // open the 'AuthAccordian' if another accordion is currently active.
    }
  };

  const handleSelectInput = (type) => {
    if (type === "splash_logo") {
      setSelectedDimension("freeform");
      setIsSplashImageModalOpen(true);
    } else {
      setActiveInputField(type);
      setSelectedDimension("freeform");
      setIsImageModalOpen(true);
    }
  };

  const handleImageSelect = (selectedImage) => {
    // setImage(selectedImage);
    setErrorImagesUpload((prevInputs) => ({
      ...prevInputs,
      [activeInputField]: selectedImage,
    }));
    setIsImageModalOpen(false);
  };

  // const handleSplashSelectInput = (event) => {
  //   setSelectedDimension("freeform");
  //   setIsSplashImageModalOpen(true);
  // };

  // const handleDimesnionSelect = (selectedRatio) => {
  //   setIsSelectBoxOpen(false);
  //   if (selectedRatio) {
  //     setSelectedDimension(selectedRatio);
  //     setIsImageModalOpen(true);
  //   }
  // };

  useEffect(() => {
    const handleOutsideClick = (event) => {
      if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
        setIsSelectBoxOpen(false);
      }
    };

    document.addEventListener("mousedown", handleOutsideClick);

    return () => {
      document.removeEventListener("mousedown", handleOutsideClick);
    };
  }, []);

  return (
    <div className="px-2">
      {/* header accordian Starts */}
      <Accordion
        expanded={activeAccordion === "HeaderAccordian"}
        onChange={handleAccordionChange}
      >
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel1a-content"
          id="panel1a-header"
        >
          <Typography style={{ fontFamily: "poppins" }}>
            Features Customization
          </Typography>
        </AccordionSummary>
        <AccordionDetails>
          <div className="p-1">
            <div>
              {/* Your modal starts here */}
              <Modal
                isOpen={isImageModalOpen}
                onRequestClose={() => setIsImageModalOpen(false)}
                style={{
                  overlay: {
                    zIndex: 1000,
                    backgroundColor: "rgba(0, 0, 0, 0.6)",
                  },
                }}
              >
                <Gallery
                  dimension="freeform"
                  onImageSelect={handleImageSelect}
                  closeModal={() => setIsImageModalOpen(false)}
                />
              </Modal>

              <Modal
                isOpen={isSplashImageModalOpen}
                onRequestClose={() => setIsSplashImageModalOpen(false)}
                style={{
                  overlay: {
                    zIndex: 1000,
                    backgroundColor: "rgba(0, 0, 0, 0.6)",
                  },
                }}
              >
                <Gallery
                  dimension="gif"
                  onImageSelect={(selectedImage) => {
                    setSplashImage(selectedImage);
                    setIsSplashImageModalOpen(false);
                    // Handle saving inputs here if needed
                  }}
                  closeModal={() => setIsSplashImageModalOpen(false)}
                />
              </Modal>

              <div className="grid grid-cols-2 gap-4">
                {/* Brand Logo  */}
                <div className="bg-gray-100 rounded-md p-2 pb-4">
                  <div className="mb-2">
                    {/* Required mark */}
                    <h1 className="text-gray-900 text-lg font-poppins font-semibold text-center mb-3 underline">
                      <span className="text-red-500 text-lg">*</span>Brand Logo
                    </h1>
                  </div>
                  <div className="flex-1 flex items-center justify-center space-x-2 ">
                    <div
                      className="border-2 border-blue-500 rounded-md flex items-center justify-center p-2 cursor-pointer w-40"
                      onClick={() => handleSelectInput("brand_logo")}
                    >
                      {errorImagesUpload.brand_logo ? (
                        <img
                          src={errorImagesUpload.brand_logo}
                          alt="data"
                          className="w-full h-full object-cover rounded-md"
                        />
                      ) : (
                        <button className="bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded-md">
                          <FiUpload size="1.5em" />
                        </button>
                      )}
                    </div>
                    {errorImagesUpload.brand_logo && (
                      <div className="flex row">
                        <div
                          className="text-blue-500 cursor-pointer ml-2"
                          onClick={() => handleSelectInput("brand_logo")}
                        >
                          <FiUpload size="1.5em" />
                        </div>
                        <div
                          className="text-red-500 cursor-pointer ml-4"
                          onClick={() =>
                            setErrorImagesUpload((prevState) => ({
                              ...prevState,
                              brand_logo: "",
                            }))
                          }
                        >
                          <AiFillDelete size="1.5em" />
                        </div>
                      </div>
                    )}
                  </div>
                </div>

                {/* Splash Logo */}
                <div className="bg-gray-100 rounded-md p-2 pb-4">
                  <div className="mb-2">
                    <h1 className="text-gray-900 text-lg font-poppins font-semibold text-center mb-3 underline">
                      Splash Logo
                    </h1>
                  </div>
                  <div className="flex-1 flex items-center justify-center space-x-2 ">
                    <div
                      className="border-2 border-blue-500 rounded-md flex items-center justify-center p-2 cursor-pointer w-40"
                      onClick={() => handleSelectInput("splash_logo")}
                    >
                      {splashImage ? (
                        <img
                          src={splashImage}
                          alt="data"
                          className="w-full h-full object-cover rounded-md"
                        />
                      ) : (
                        <button className="bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded-md">
                          <FiUpload size="1.5em" />
                        </button>
                      )}
                    </div>
                    {splashImage && (
                      <div className="flex row">
                        <div
                          className="text-blue-500 cursor-pointer ml-2"
                          onClick={() => handleSelectInput("splash_logo")}
                        >
                          <FiUpload size="1.5em" />
                        </div>
                        <div
                          className="text-red-500 cursor-pointer ml-4"
                          onClick={() => setSplashImage(null)}
                        >
                          <AiFillDelete size="1.5em" />
                        </div>
                      </div>
                    )}
                  </div>
                </div>

                {/*  Page Not Found  */}
                <div className="bg-gray-100 rounded-md p-2 pb-4">
                  <div className="mb-2">
                    <h1 className="text-gray-900 text-lg font-poppins font-semibold text-center mb-3 underline">
                      Page Not Found (404)
                    </h1>
                  </div>
                  <div className="flex-1 flex items-center justify-center space-x-2 ">
                    <div
                      className="border-2 border-blue-500 rounded-md flex items-center justify-center p-2 cursor-pointer w-20 h-20"
                      onClick={() => handleSelectInput("page_not_found_img")}
                    >
                      {errorImagesUpload.page_not_found_img ? (
                        <img
                          src={errorImagesUpload.page_not_found_img}
                          alt="data"
                          className="w-full h-full object-cover rounded-md"
                        />
                      ) : (
                        <button className="bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded-md">
                          <FiUpload size="1.5em" />
                        </button>
                      )}
                    </div>
                    {errorImagesUpload.page_not_found_img && (
                      <div className="flex row">
                        <div
                          className="text-blue-500 cursor-pointer ml-2"
                          onClick={() =>
                            handleSelectInput("page_not_found_img")
                          }
                        >
                          <FiUpload size="1.5em" />
                        </div>
                        <div
                          className="text-red-500 cursor-pointer ml-4"
                          onClick={() =>
                            setErrorImagesUpload((prevState) => ({
                              ...prevState,
                              page_not_found_img: "",
                            }))
                          }
                        >
                          <AiFillDelete size="1.5em" />
                        </div>
                      </div>
                    )}
                  </div>
                </div>

                {/* Something Went Wrong (500) */}
                <div className="bg-gray-100 rounded-md p-2 pb-4">
                  <div className="mb-2">
                    <h1 className="text-gray-900 text-lg font-poppins font-semibold text-center mb-3 underline">
                      Something Went Wrong (500)
                    </h1>
                  </div>
                  <div className="flex-1 flex items-center justify-center space-x-2 ">
                    <div
                      className="border-2 border-blue-500 rounded-md flex items-center justify-center p-2 cursor-pointer w-20 h-20"
                      onClick={() => handleSelectInput("some_went_wrong_img")}
                    >
                      {errorImagesUpload.some_went_wrong_img ? (
                        <img
                          src={errorImagesUpload.some_went_wrong_img}
                          alt="data"
                          className="w-full h-full object-cover rounded-md"
                        />
                      ) : (
                        <button className="bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded-md">
                          <FiUpload size="1.5em" />
                        </button>
                      )}
                    </div>
                    {errorImagesUpload.some_went_wrong_img && (
                      <div className="flex row">
                        <div
                          className="text-blue-500 cursor-pointer ml-2"
                          onClick={() =>
                            handleSelectInput("some_went_wrong_img")
                          }
                        >
                          <FiUpload size="1.5em" />
                        </div>
                        <div
                          className="text-red-500 cursor-pointer ml-4"
                          onClick={() =>
                            setErrorImagesUpload((prevState) => ({
                              ...prevState,
                              some_went_wrong_img: "",
                            }))
                          }
                        >
                          <AiFillDelete size="1.5em" />
                        </div>
                      </div>
                    )}
                  </div>
                </div>
                {/* No Data Found */}
                <div className="bg-gray-100 rounded-md p-2 pb-4">
                  <div className="mb-2">
                    <h1 className="text-gray-900 text-lg font-poppins font-semibold text-center mb-3 underline">
                      No Data Found
                    </h1>
                  </div>
                  <div className="flex-1 flex items-center justify-center space-x-2 ">
                    <div
                      className="border-2 border-blue-500 rounded-md flex items-center justify-center p-2 cursor-pointer w-20 h-20"
                      onClick={() => handleSelectInput("no_data_found_img")}
                    >
                      {errorImagesUpload.no_data_found_img ? (
                        <img
                          src={errorImagesUpload.no_data_found_img}
                          alt="data"
                          className="w-full h-full object-cover rounded-md"
                        />
                      ) : (
                        <button className="bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded-md">
                          <FiUpload size="1.5em" />
                        </button>
                      )}
                    </div>
                    {errorImagesUpload.no_data_found_img && (
                      <div className="flex row">
                        <div
                          className="text-blue-500 cursor-pointer ml-2"
                          onClick={() => handleSelectInput("no_data_found_img")}
                        >
                          <FiUpload size="1.5em" />
                        </div>
                        <div
                          className="text-red-500 cursor-pointer ml-4"
                          onClick={() =>
                            setErrorImagesUpload((prevState) => ({
                              ...prevState,
                              no_data_found_img: "",
                            }))
                          }
                        >
                          <AiFillDelete size="1.5em" />
                        </div>
                      </div>
                    )}
                  </div>
                </div>
                {/* Product Not Found */}
                <div className="bg-gray-100 rounded-md p-2 pb-4">
                  <div className="mb-2">
                    <h1 className="text-gray-900 text-lg font-poppins font-semibold text-center mb-3 underline">
                      Product Not Found
                    </h1>
                  </div>
                  <div className="flex-1 flex items-center justify-center space-x-2 ">
                    <div
                      className="border-2 border-blue-500 rounded-md flex items-center justify-center p-2 cursor-pointer w-20 h-20"
                      onClick={() => handleSelectInput("product_not_found_img")}
                    >
                      {errorImagesUpload.product_not_found_img ? (
                        <img
                          src={errorImagesUpload.product_not_found_img}
                          alt="data"
                          className="w-full h-full object-cover rounded-md"
                        />
                      ) : (
                        <button className="bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded-md">
                          <FiUpload size="1.5em" />
                        </button>
                      )}
                    </div>
                    {errorImagesUpload.product_not_found_img && (
                      <div className="flex row">
                        <div
                          className="text-blue-500 cursor-pointer ml-2"
                          onClick={() =>
                            handleSelectInput("product_not_found_img")
                          }
                        >
                          <FiUpload size="1.5em" />
                        </div>
                        <div
                          className="text-red-500 cursor-pointer ml-4"
                          onClick={() =>
                            setErrorImagesUpload((prevState) => ({
                              ...prevState,
                              product_not_found_img: "",
                            }))
                          }
                        >
                          <AiFillDelete size="1.5em" />
                        </div>
                      </div>
                    )}
                  </div>
                </div>
              </div>

              <div className="my-8 border-b border-gray-200"></div>

              {/* Support Phone Number Input */}
              <div className="my-4">
                <label
                  htmlFor="supportPhone"
                  className="block mb-2 text-sm font-medium text-gray-900"
                >
                  Support Phone Number
                </label>
                <input
                  type="tel"
                  id="supportPhone"
                  value={supportPhoneNumber}
                  onChange={(e) => setSupportPhoneNumber(e.target.value)}
                  className={`bg-gray-50 border ${
                    errors.phone ? "border-red-500" : "border-gray-300"
                  } text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5`}
                  placeholder="+91-XXXXXXXXXX"
                  pattern="\+91-[0-9]{10}"
                  title="Phone number must be in the format: +91-XXXXXXXXXX"
                />
                {/* {errors.phone && (
                  <p className="text-red-500 text-sm mt-2">{errors.phone}</p>
                )} */}
              </div>

              {/* Support Chat Script Link Input */}
              <div className="mb-4">
                <label
                  htmlFor="supportChat"
                  className="block mb-2 text-sm font-medium text-gray-900"
                >
                  Support Chat Script Link
                </label>
                <input
                  type="text"
                  id="supportChat"
                  value={supportChatLink}
                  onChange={(e) => setSupportChatLink(e.target.value)}
                  className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5"
                  placeholder="https://yourchatlink.com"
                />
              </div>

              <div className="my-8 border-b border-gray-200"></div>

              {/* ReactSwitch for Multi-Keyword Toggle */}
              <div className="flex items-center mb-4">
                <label htmlFor="multiKeywordToggle" className="mr-2">
                  Enable Manual Search Keyword:
                </label>
                <ReactSwitch
                  id="multiKeywordToggle"
                  onChange={setIsMultiKeywordEnabled}
                  checked={isMultiKeywordEnabled}
                />
              </div>

              {/* Multi-Keyword Inputs Section */}
              {isMultiKeywordEnabled && (
                <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 gap-4 my-4">
                  {multiKeywords &&
                    multiKeywords.length != 0 &&
                    multiKeywords.map(({ id, value }, index) => (
                      <div
                        key={id}
                        className={`flex items-center ${
                          index % 2 !== 0 ? "sm:justify-end" : ""
                        }`}
                      >
                        <input
                          type="text"
                          value={value}
                          onChange={(e) =>
                            handleKeywordChange(id, e.target.value)
                          }
                          className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5"
                        />
                        <button
                          onClick={() => handleDeleteKeyword(id)}
                          className="ml-2 text-red-500"
                        >
                          <AiFillDelete />
                        </button>
                      </div>
                    ))}
                  <div className="col-span-1 sm:col-span-2 lg:col-span-3">
                    <button
                      onClick={handleAddKeywordInput}
                      className="text-white bg-blue-500 hover:bg-blue-700 font-medium rounded-lg text-sm px-4 py-2 text-center mt-4"
                    >
                      Add Keyword
                    </button>
                  </div>
                </div>
              )}

              <button
                onClick={handleSubmit}
                className={`bg-gradient-to-r from-blue-500 to-blue-600 mt-4 hover:from-blue-500 hover:to-blue-700 transition duration-300 ease-in-out text-white font-bold py-2 px-4 rounded-md block mx-auto ${
                  Object.keys(errors).length > 0
                    ? "opacity-50 cursor-not-allowed"
                    : ""
                }`}
                disabled={Object.keys(errors).length > 0}
              >
                {Object.keys(errors).length > 0
                  ? "Fill the required fields"
                  : dataSubmitted
                  ? "Update"
                  : "Save"}
              </button>
            </div>
          </div>
        </AccordionDetails>
      </Accordion>
      {/* header accordian ends */}
    </div>
  );
};

export default HeaderCustomization;
