import React, { useRef } from "react";
import { Swiper, SwiperSlide } from "swiper/react";
import SwiperCore, { Navigation } from "swiper/core";
import "swiper/css";
import "swiper/css/navigation";
import "swiper/css/pagination";
import "swiper/css/bundle";
import { FaArrowRight, FaArrowLeft } from "react-icons/fa";
import "./style.css";
import "swiper/swiper.min.css";
import placeholderImage from "../../../assets/paceholder2.png";

SwiperCore.use([Navigation]);

const Card = ({
  image,
  header,
  headerColor,
  subtext,
  subtextColor,
  bgColor,
}) => {
  const truncateText = (text, length) => {
    if (text.length <= length) return text;
    return `${text.substring(0, length)}...`;
  };

  const defaultHeaderText = "Header Text";
  const defaultSubtext = "Sub Text";

  return (
    <div
      className={`flex-1 mx-2 shadow-md rounded-lg overflow-hidden `}
      style={{ backgroundColor: `${bgColor}` }}
    >
      <div className="p-4 flex items-center justify-center">
        <img
          src={image ? image : placeholderImage}
          alt="Icon or Image"
          className="w-16 h-16 rounded-lg"
        />
      </div>
      <div className="p-4 flex items-center justify-center border-t border-b border-gray-200">
        <h2
          className={`text-lg font-semibold truncate`}
          style={{ color: `${headerColor}` }}
        >
          {header || defaultHeaderText}
        </h2>
      </div>
      <div className="p-4 flex items-center justify-center">
        <p className={`truncate`} style={{ color: `${subtextColor}` }}>
          {truncateText(subtext || defaultSubtext, 50)}
        </p>
      </div>
    </div>
  );
};

const Preview = ({ infoBoxes }) => {
  const swiperRef = useRef(null);

  const goToNext = () => {
    if (swiperRef.current) {
      swiperRef.current.swiper.slideNext();
    }
  };

  const goToPrev = () => {
    if (swiperRef.current) {
      swiperRef.current.swiper.slidePrev();
    }
  };

  // If there's no card data, don't render the component
  if (infoBoxes.length === 0) {
    return null;
  }

  return (
    <div className="flex flex-col  items-center justify-center">
      <div className="flex justify-center">
        <h1 className="text-lg font-semibold text-gray-600">Preview</h1>
      </div>
      <div className="mx-4 my-4 bg-gray-200 rounded-xl shadow-lg p-4 overflow-hidden relative fifteenth-step ">
        {/* Navigation Arrow - Previous */}
        <div
          className="absolute top-1/2 transform -translate-y-1/2 left-0 custom-swiper-button-prev"
          onClick={goToPrev}
        >
          <FaArrowLeft
            className="text-gray-500 hover:text-gray-800"
            size={20}
          />
        </div>
        <div className="flex justify-center flex-row w-[38rem] lg:w-[42rem]">
          {infoBoxes.length === 1 ? (
            <div className="single-card-container min-w-[18rem]">
              <Card
                image={infoBoxes[0].icon}
                header={infoBoxes[0].header_text}
                subtext={infoBoxes[0].sub_text}
                bgColor={infoBoxes[0].background_colour}
                headerColor={infoBoxes[0].header_colour}
                subtextColor={infoBoxes[0].text_colour}
              />
            </div>
          ) : (
            <Swiper
              navigation={{
                nextEl: ".custom-swiper-button-next",
                prevEl: ".custom-swiper-button-prev",
              }}
              ref={swiperRef}
              slidesPerView={1}
              spaceBetween={30}
              breakpoints={{
                640: { slidesPerView: 1 },
                768: { slidesPerView: 2 },
                1024: { slidesPerView: 2 },
                // 1440: { slidesPerView: 3 },
              }}
            >
              {infoBoxes.map((infoBox) => (
                <SwiperSlide key={infoBox.id}>
                  <Card
                    image={infoBox.icon}
                    header={infoBox.header_text}
                    subtext={infoBox.sub_text}
                    bgColor={infoBox.background_colour}
                    headerColor={infoBox.header_colour}
                    subtextColor={infoBox.text_colour}
                  />
                </SwiperSlide>
              ))}
            </Swiper>
          )}
        </div>

        {/* Navigation Arrow - Next */}
        <div
          className="absolute top-1/2 transform -translate-y-1/2 right-0 custom-swiper-button-next"
          onClick={goToNext}
        >
          <FaArrowRight
            className="text-gray-500 hover:text-gray-800"
            size={20}
          />
        </div>
      </div>
    </div>
  );
};

export default Preview;
